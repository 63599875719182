body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #1111119d;
  height: 100vh;
  overflow: hidden;
}

ol, ul {
  padding-left: 30px;
}

.back {
  background: #111111;
}

.background,
.background::before,
.background::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250vw;
  height: 300vw;
  margin-left: -125vw;
  transform-origin: 50% 50%;
  background-color: #00000000;
  border-radius: 38% 42%;
  box-shadow: inset 0 0 10vw rgb(255, 0, 255);
  animation: spin 6s infinite linear;
  mix-blend-mode: multiply;
}

.background::before {
  width: 105%;
  height: 95%;
  margin-top: -125vw;
  transform-origin: 49% 51%;
  border-radius: 40% 38%;
  box-shadow: inset 0 0 10vw rgb(255, 255, 0);
  animation: spin 13s infinite linear;
}

.background::after {
  width: 102%;
  height: 98%;
  margin-top: -125vw;
  transform-origin: 51% 49%;
  border-radius: 48% 42%;
  box-shadow: inset 0 0 10vw rgb(0, 255, 255);
  animation: spin 10s infinite linear;
}

.background2,
.background2::before,
.background2::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250vw;
  height: 300vw;
  margin-left: -140vw;
  transform-origin: 50% 50%;
  background-color: #00000000;
  border-radius: 38% 42%;
  box-shadow: inset 0 0 10vw rgb(13, 255, 0);
  animation: spin 10s infinite linear;
  mix-blend-mode: multiply;
}

.background2::before {
  width: 105%;
  height: 95%;
  margin-top: -125vw;
  transform-origin: 49% 51%;
  border-radius: 40% 38%;
  box-shadow: inset 0 0 10vw rgb(255, 0, 0);
  animation: spin 13s infinite linear;
}

.background2::after {
  width: 102%;
  height: 98%;
  margin-top: -125vw;
  transform-origin: 51% 49%;
  border-radius: 48% 42%;
  box-shadow: inset 0 0 10vw rgb(38, 0, 255);
  animation: spin 10s infinite linear;
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}

.glow-on-hover {
  width: 130px;
  height: 30px;
  margin: 4px;
  border: none;
  outline: none;
  color: #dbdbdb;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  font-weight: bold;
}

.square {
  /* background: #dbdbdb;
  border: 1px solid #999;
  float: left;
  font-size: 50px;
  font-weight: bold;
  line-height: 34px;
  height: 100px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 100px; */

  color: rgba(217, 0, 255, 0.563);
  /* filter: invert(100%); */
  font-size: 50px;
  font-weight: bold;
  line-height: 34px;
  float: left;
  
  background: radial-gradient(ellipse at top, #e66465, transparent),
            radial-gradient(ellipse at bottom, #4d9f0c, transparent);
  width: 100px;
  height: 100px;
  border: 5px solid rgba(165, 42, 42, 0.405);
  border-radius: 50px;
  /* border-image: linear-gradient(45deg, gold, deeppink) 1; */
  /* border-image: linear-gradient(45deg, rgb(151, 24, 151), rgba(140, 140, 19, 0.8), rgba(9, 128, 128, 0.8)) 1;
  clip-path: circle(6rem at 12rem 8rem);
  animation: huerotate 5s infinite linear;
  filter: hue-rotate(360deg); */
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  padding: 10px;
  display: flex;
  flex-direction: row;
  background: transparent;

  /* -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(5px); */
  /* text-align: center; */
  /* align-items: center; */
  /* justify-content: center; */
}

/* @property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}

:root {
	--d: 1000ms;
	--angle: 90deg;
	--gradX: 10%;
	--gradY: 50%;
	--c1: #4E4E4E;
	--c2: #111;
}

@keyframes borderRotate {
	100% {
		--angle: 460deg;
	}
} */

@keyframes huerotate {
  0% {
      filter: hue-rotate(0deg);
  }
  100% {
      filter: hue-rotate(360deg);
  }
}

.game-board {
  /* font-size: 3vw;
	margin: max(1rem, 3vw);
	border: 0.50rem solid;
	padding: 3vw;
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: borderRotate var(--d) linear infinite forwards; */

  width: 300px;
  height: 300px;
  border: 9px solid transparent;
  filter: invert(100%);
  /* border-image: linear-gradient(45deg, gold, deeppink) 1; */
  border-image: linear-gradient(45deg, rgb(151, 24, 151), rgba(140, 140, 19, 0.8), rgba(9, 128, 128, 0.8)) 1;
  clip-path: inset(0px round 25px);
  animation: huerotate 2s infinite linear;
  filter: hue-rotate(-0.25turn);
}

/* @property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
} */

.game-info {
  text-align: center;
  width: 300px;
  height: 300px;
  margin-left: 100px;
}

.empty {
  width: 300px;
  height: 300px;
}

.statusLine {
  background: #dbdbdb;
  line-height: 34px;
  height: .5px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 0;
  width: 300px;
  border-radius: 50px;
}